import React from "react";

const PricingContent = [
  {
    title: "",
    price: "100",
    priceMeta: "gift card",
    packageName: "",
    skewClass: "skew-right",
    text: 'Get a $100 visa gift card when you find a wedding venue through Wedding Pricer!',
    priceList: [
      {
        list: "60-day chat history",
        listClass: "",
      },
      {
        list: "Basic widget customization",
        listClass: "",
      },
      {
        list: "Ticketing system",
        listClass: "disable",
      },
      {
        list: "Data security",
        listClass: "disable",
      },
    ],
    animatinDelay: "0",
  },
  {
    title: "",
    price: "50",
    priceMeta: "gift card",
    packageName: "",
    skewClass: "skew-left",
    text: 'Get a $50 visa gift card when you find a band, DJ, photographer, videographer, or florist through Wedding Pricer!',
    priceList: [
      {
        list: "60-day chat history",
        listClass: "",
      },
      {
        list: "Basic widget customization",
        listClass: "",
      },
      {
        list: "Ticketing system",
        listClass: "",
      },
      {
        list: "Data security",
        listClass: "disable",
      },
    ],
    animatinDelay: "100",
  },
  {
    title: "",
    price: "25",
    priceMeta: "gift card",
    packageName: "",
    skewClass: "skew-right",
    text: 'Get a $25 gift card when you find a baker, stylist, officiant, or decor provider through Wedding Pricer!',
    priceList: [
      {
        list: "60-day chat history",
        listClass: "",
      },
      {
        list: "Basic widget customization",
        listClass: "",
      },
      {
        list: "Ticketing system",
        listClass: "",
      },
      {
        list: "Data security",
        listClass: "",
      },
    ],
    animatinDelay: "200",
  },
];

const PricingThree = () => {
  return (
    <div className="row justify-content-center">
      {PricingContent.map((item, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={item.animatinDelay}
        >
          <div className={`pr-table-wrapper ${item.skewClass}`}>
            <div className={`pack-name font-slab ${item.packageName}`}>
              <span>{item.title}</span>
            </div>
            <div className="price font-slab">${item.price}</div>
            <p className="user-condition">{item.priceMeta}</p>
            <img src="images/shape/114.svg" alt="shape" className="line" />
            <p>
              {item.text}
            
            </p>
            {/* <ul>
              {item.priceList.map((val, i) => (
                <li className={val.listClass} key={i}>
                  {val.list}
                </li>
              ))}
            </ul> */}
            {/* <a href="#" className="subscribe-btn font-rubik">
              Subscribe Now
            </a> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PricingThree;
