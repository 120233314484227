import React from "react";

const FeatureContent = [
  {
    icon: "61",
    meta: "Sign up",
    subTitle: `Sign up through our <a href='/signup/vendor' target='_blank'>vendor signup link</a> and complete your profile`,
  },
  {
    icon: "62",
    meta: "Message potential clients",
    subTitle: `Send proposals to clients that you would like to work with`,
  },
  {
    icon: "60",
    meta: "Coordinate your events",
    subTitle: `Communicate with potential clients and arrange to work with them!`,
  }
];

const FeaturesEight = () => {
  return (
    <div className="row justify-content-center pb-80">
      <div className="title-style-five text-center pt-80 pb-80">
        <h2>
          <span>Are you a vendor looking for clients?</span>
        </h2>
      </div>
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div className="block-style-thirteen">
            <div className="icon">
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <div className="title font-rubik">{val.meta}</div>
            <p dangerouslySetInnerHTML={{__html: val.subTitle}} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeaturesEight;
