import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SignUpFormVendor from "../../../../components/contact/form/SignUpFormVendor";

const SignUpVendor = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>Sign Up || Wedding Pricer</title>
      </Helmet>
      <div className="user-data-page clearfix d-lg-flex">
        <div className="illustration-wrapper d-flex align-items-center justify-content-between flex-column">
          <h3 className="font-rubik">
            Your next great clients<br/> are right around the corner..
          </h3>
          <div className="illustration-holder">
            <img
              src="/images/assets/reimages/Couple floating with balloons-rafiki.svg"
              alt="Work illustrations by Storyset"
              className="illustration"
            />
          </div>
        </div>

          <div className="form-wrapper">
            <div className="d-flex justify-content-between">
              <Link className="font-rubik go-back-button" to="/">
                Go to home
              </Link>
            </div>

            <div className="mt-30">
              <h2>Sign up and start finding new clients!</h2>
              <p className="header-info pt-30 pb-50">
                Already have an account? <Link to="/login">Login</Link>
              </p>
            </div>
            <SignUpFormVendor />

          </div>
        
      </div>
    </div>
  );
};

export default SignUpVendor;
