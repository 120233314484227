import React from "react";
// Route Specific
import { Routes, Route } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

// All HOME PAGE ROUTES
import EventOrganizer from "../views/all-home-pages/EventOrganizer";

// All INNER PAGES ROUTES START FROM BELLOW

// FEATURES DROPDOWN ALL ROUTES
import Login from "../views/inner-pages/features/miscellaneous/Login";
import SignUpUser from "../views/inner-pages/features/miscellaneous/SignUpUser";

// BLOGS DROPDOWN ALL ROUTES
import BlogV5 from "../views/inner-pages/blog-pages/BlogV5";

// Not Found Page
import NotFound from "../views/NotFound";

import TermsConditionsUser from "../views/inner-pages/features/miscellaneous/TermsConditionsUser";
import TermsConditionsVendor from "../views/inner-pages/features/miscellaneous/TermsConditionsVendor";
import SignUpUserComplete from "../views/inner-pages/features/miscellaneous/SignUpUserComplete";
import SignUpVendor from "../views/inner-pages/features/miscellaneous/SignUpVendor";
import SignUpVendorComplete from "../views/inner-pages/features/miscellaneous/SignUpVendorComplete";

const AllRoutes = () => {
  return (
    <>
      <ScrollTopBehaviour />
      <Routes>
        <Route path="/" element={<EventOrganizer />} />

        <Route path="/login" element={<Login />} />
        <Route path="/signup/user" element={<SignUpUser />} />
        <Route path="/signup/user/complete" element={<SignUpUserComplete />} />
        <Route path="/signup/vendor" element={<SignUpVendor />} />
        <Route path="/signup/vendor/complete" element={<SignUpVendorComplete />} />

        <Route path="/tos/user" element={<TermsConditionsUser />} />
        <Route path="/tos/vendor" element={<TermsConditionsVendor />} />

        <Route path="*" element={<NotFound />} />

      </Routes>
    </>
  );
};

export default AllRoutes;
