import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import AllRoutes from "./router/AllRoutes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import ReactGA from 'react-ga4';
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";
import "bootstrap/dist/js/bootstrap";

const App = () => {
  useEffect(() => {
    const TRACKING_ID = 'G-X1FL3WFDVX';
    ReactGA.initialize(TRACKING_ID);

    ReactGA.send("pageview");

    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);

  return (
    <>
      <Helmet>
        <title>Wedding Pricer - Your Marketplace for Wedding Vendors</title>
        <meta
          name="description"
          content="Discover and compare wedding vendors to make your special day perfect. Find venues, photographers, florists, and more on Wedding Pricer."
        />
        <meta name="keywords" content="wedding vendors, wedding marketplace, wedding services, wedding planning, wedding venue, wedding photographer" />
        <meta name="author" content="Wedding Pricer" />
        <meta name="robots" content="index, follow" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://weddingpricer.com/" />
        <meta property="og:title" content="Wedding Pricer - Your Marketplace for Wedding Vendors" />
        <meta
          property="og:description"
          content="Find and compare top wedding vendors for your perfect day. Explore venues, photographers, florists, and more at Wedding Pricer."
        />
        <meta property="og:image" content="https://weddingpricer.com/images/assets/reimages/Wedding planner-pana.svg" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://weddingpricer.com/" />
        <meta property="twitter:title" content="Wedding Pricer - Your Marketplace for Wedding Vendors" />
        <meta
          property="twitter:description"
          content="Plan your wedding with ease by finding the best wedding vendors. Discover venues, photographers, florists, and more at Wedding Pricer."
        />
        <meta property="twitter:image" content="https://weddingpricer.com/images/assets/reimages/Wedding planner-pana.svg" />

        {/* Additional Meta Tags */}
        <link rel="canonical" href="https://weddingpricer.com/" />
      </Helmet>

      <ScrollToTop />

      <AllRoutes />
    </>
  );
};

export default App;
