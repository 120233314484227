import React from "react";
import { Link } from "react-router-dom";

const linksFooterContent = [
  {
    itemName: "Home",
    routePath: "/",
  },
  {
    itemName: "Blog",
    routePath: "/blog",
  },
  {
    itemName: "User Signup",
    routePath: "/signup/user",
  },
  {
    itemName: "Vendor Signup",
    routePath: "/signup/vendor",
  },
];

const legalFooterContent = [
  {
    itemName: "Vendor terms of service",
    routePath: "/tos/vendor",
  },
  {
    itemName: "User terms of service",
    routePath: "/tos/user",
  }
];

const productsFooterContent = [
  {
    itemName: "Wedding&nbsp;Pricer",
    routePath: "https://app.weddingpricer.com",
  }
];

const FooterFour = () => {
  return (
    <div className="row justify-content-end">
      <div className="col-xl-4 col-lg-3 col-12 footer-about-widget">
        <div className="logo">
            Support:
            {/* <img src="images/logo/deski_05.svg" alt="" /> */}
          
        </div>
        <ul className="font-rubik mt-10">
          <li>
            <a href="mailto:help@weddingpricer.com">help@weddingpricer.com</a>
          </li>
          {/* <li>
            <a href="tel:+761 412 3224">+761 412 3224</a>
          </li> */}
        </ul>
      </div>
      {/* /.footer-list */}

      <div className="col-lg-3 col-md-4 footer-list">
        <h5 className="footer-title font-slab">Links</h5>
        <ul>
          {linksFooterContent.map((list, i) => (
            <li key={i}>
              <a href={list.routePath}>{list.itemName}</a>
            </li>
          ))}
        </ul>
      </div>

      {/* /.footer-list */}
      <div className="col-lg-3 col-md-4 footer-list">
        <h5 className="footer-title font-slab">Legal</h5>
        <ul>
          {legalFooterContent.map((list, i) => (
            <li key={i}>
              <Link to={list.routePath}>{list.itemName}</Link>
            </li>
          ))}
        </ul>
      </div>
      {/* /.footer-list */}

      <div className="col-xl-2 col-lg-3 col-md-4 footer-list">
        <h5 className="footer-title font-slab">Products</h5>
        <ul className="pe-5">
          {productsFooterContent.map((list, i) => (
            <li key={i}>
              <a href={list.routePath} dangerouslySetInnerHTML={{__html: list.itemName}} />
              {/* <Link to={list.routePath}>{list.itemName}</Link> */}
            </li>
          ))}
        </ul>
      </div>

    </div>
  );
};

export default FooterFour;
