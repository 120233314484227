import React from "react";

const FeatureContent = [
  {
    icon: "Marriage-rafiki",
    meta: "Sign up",
    subTitle: `Create an account with Wedding Pricer`,
  },
  {
    icon: "Wedding planner-amico",
    meta: "Submit an enquire",
    subTitle: `Select what vendors you need, and describe what you'd like from them`,
  },
  {
    icon: "Chat-cuate",
    meta: "Engage with vendors",
    subTitle: `Coordinate with vendors in your area that want to work with you`,
  },
];

const FeatureNine = () => {
  return (
    <div className="row justify-content-center">
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div className="block-style-fourteen">
            <div className="illustration">
              <img src={`images/assets/reimages/${val.icon}.svg`} alt="Work illustrations by Storyset" />
            </div>
            <div className="title">{val.meta}</div>
            <p className="font-rubik">{val.subTitle}</p>
          </div>
          {/* /.block-style-fourteen */}
        </div>
      ))}
    </div>
    // /.row
  );
};

export default FeatureNine;
