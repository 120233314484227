import React, { useState } from "react";
import { Link } from "react-router-dom";
import MegaMenu from "./mega-menu/MegaMenu";
import MegaMenuMobile from "./mega-menu/MegaMenuMobile";

const Header = () => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 68) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-one bg-none  fixed"
            : "theme-main-menu sticky-menu theme-menu-one bg-none"
        }
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="logo my-header">
            <Link to="/">
              <h2><span>Wedding Pricer</span></h2>
            </Link>
          </div>
          <div className="logo my-header-mini">
            <Link to="/">
              <h2><span>WP</span></h2>
            </Link>
          </div>

          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="container nav-container">
              <div
                className="navbar-collapse collapse"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex align-items-center">
                  <MegaMenu />

                  <ul className=" right-button-group d-flex align-items-center justify-content-center">
                    <li>
                      <Link to="/login" className="signIn-action" style={{ fontSize: '18px', textTransform: 'none'}}>
                        Login
                      </Link>
                    </li>
                    <li>
                      <Link to="/signup/user" className="signUp-action" style={{width: 'auto', paddingLeft: '20px', paddingRight: '20px'}}>
                        Find wedding vendors now
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
          {/* End nav */}
        </div>

        <MegaMenuMobile />
        {/* 	End Mega Menu for Mobile */}
      </div>
      {/* /.theme-main-menu */}
    </>
  );
};

export default Header;
