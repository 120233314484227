import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import CopyRight from "../../../../components/footer/CopyRight";
import ScrollspyNav from "react-scrollspy-nav";
import FooterFour from "../../../../components/footer/FooterFour";

const TermsConditionsVendor = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Vendor Terms of Service
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <ScrollspyNav
            scrollTargetIds={["opt1", "opt2", "opt3", "opt4", "opt5", "opt6"]}
            activeNavClass="active"
            offset={170}
            scrollDuration="300"
          >
            <div className="row align-items-start">
              <div className="col-lg-4 sidenav-sticky">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" href="#opt1">
                      1. Terms of Service for Vendors
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-8">
                {/* Tab panes */}
                <div className="tab-content ">
                  <div id="opt1">
                    <h2 className="font-gilroy-bold">Vendor terms of service</h2>
                    <div className="update-date">LAST UPDATED: 10/01/2024</div>
                    <p>
                      Welcome to Wedding Pricer. These Terms of Service (“Terms”) govern your use of our website and services provided by 16301380 Canada Inc. (“Wedding Pricer,” “we,” “our,” or “us”). By accessing or using our platform, you agree to these Terms.
                    </p>
                    <h3>1. Services Provided</h3>
                    <ul>
                      <li>Platform Overview: Wedding Pricer is an online platform that connects couples planning weddings with wedding vendors. Couples use the platform to post their wedding needs, including budget, location, and preferred services, while vendors submit offers to fulfill these needs.</li>
                      <li>Vendor Participation: By registering as a vendor, you gain access to the platform where you can view and respond to requests from couples seeking wedding services. You are responsible for managing your own interactions with clients and fulfilling the terms of any agreements you make.</li>
                    </ul>
                    
                    <h3>2. Commission Payment</h3>
                    <ul>
                      <li>Commission Fee: Wedding Pricer charges a flat commission fee for connecting vendors with clients through our platform. The fee is calculated based on the total payment you receive from clients obtained via Wedding Pricer.</li>
                      <li>Fee Structure: The commission is due once the vendor has worked with the client and the client has made payment for the services provided.</li>
                      <li>Fee Changes: Wedding Pricer reserves the right to change the commission fee at its discretion. Any changes will be communicated to vendors via email and posted on our website. Vendors are responsible for regularly reviewing these Terms. The new fee structure will apply to transactions occurring after the change.</li>
                      <li>Payment Deadline: Commission fees must be paid within 15 days of receiving payment from a client obtained through Wedding Pricer. Payments must be made through the designated payment method provided by Wedding Pricer.</li>
                    </ul>

                    <h3>3. Reporting Obligations</h3>
                    <ul>
                      <li>Engagement Reporting: Vendors must report all client engagements that originated through Wedding Pricer within 30 days of taking a deposit or signing a contract. This includes posting the date of service (i.e., the wedding date) on the platform to ensure proper tracking of commissions due.</li>
                      <li>Reporting Process: Reports must be submitted through the platform’s designated reporting system, which will capture necessary details including the client’s name, service details, and date of service.</li>
                    </ul>
          
                    <h3>4. Non-Payment Consequences</h3>
                    <ul>
                      <li>Interest Charges: A 2% monthly interest charge will be applied to any outstanding commission fees that are not paid within the 15-day deadline.</li>
                      <li>Account Suspension: Wedding Pricer reserves the right to suspend the vendor’s account at its sole discretion for non-payment or late payments until all outstanding fees are settled.</li>
                    </ul>

                    <h3>5. Circumvention</h3>
                    <ul>
                      <li>Inconvenience Fees:
                        <ul>
                          <li>First Offense: A $500 inconvenience fee will be added to the vendor’s bill if Wedding Pricer incurs additional costs to pursue payment or rectify circumvention.</li>
                          <li>Second Offense: A $1000 inconvenience fee will be added to the vendor’s bill for subsequent offenses.</li>
                          <li>Third Offense: A $1500 inconvenience fee will be added to the vendor’s bill, and the vendor’s account will be banned from the platform.</li>
                        </ul>
                      </li>
                      <li>Deliberate Circumvention: If deliberate circumvention is detected, the vendor will be immediately banned from the platform. If the vendor has conducted business with a client from Wedding Pricer under these circumstances, an additional $1500 inconvenience fee will be applied.</li>
                    </ul>
   
                    <h3>6. Dispute Resolution</h3>
                    <ul>
                      <li>Escalation Clause:
                        <ul>
                          <li>Initial Negotiation: Parties have 30 days to negotiate directly. Costs, if any, will be shared equally.</li>
                          <li>Mediation: If negotiations fail, mediation will be conducted with a mediator selected by a professional mediation organization. The vendor will bear the costs of mediation.</li>
                          <li>Binding Arbitration: If mediation fails, arbitration will be conducted with an arbitrator selected by a professional arbitration organization. The vendor will bear the costs of arbitration. The arbitrator’s decision will be final and binding.</li>
                          <li>Final Decision Implementation: The arbitrator’s decision must be implemented within 15 days.</li>
                        </ul>
                      </li>
                    </ul>
    
                    <h3>7. Liability and Disclaimers</h3>
                    <ul>
                      <li>Vendor Liability: Vendors are solely liable for all matters related to their services and pricing. Wedding Pricer is not responsible for any issues arising from the vendor's services or pricing.</li>
                    </ul>

                    <h3>8. Uploads and Use of Vendor Assets</h3>
                    <ul>
                      <li>Uploads: Vendors may upload photos, videos, and other files (“Assets”) to the platform to represent their services and offerings.</li>
                      <li>Permissions: By uploading these Assets, vendors grant Wedding Pricer permission to store, process, analyze, and use these files, including for machine learning and analytical purposes. Vendors also grant permission for these Assets to be associated with their public profile and displayed to other users of the platform.</li>
                      <li>Ownership and Responsibility: Vendors retain ownership of the uploaded Assets but are solely responsible for ensuring they have the necessary rights and permissions to use and upload these materials.</li>
                    </ul>

                    <h3>9. Termination</h3>
                    <ul>
                      <li>Conditions for Suspension or Termination: Vendor accounts may be suspended or terminated for breaches of the Terms of Service, non-payment of commission fees, or harassing/unprofessional behavior.</li>
                    </ul>

                    <h3>10. Changes to Terms of Service</h3>
                    <ul>
                      <li>Modification: Wedding Pricer reserves the right to modify or update these Terms at any time, at its sole discretion.</li>
                      <li>Notification: Changes will be communicated via email and posted on our website. Vendors are required to regularly review the Terms, and continued use of the platform constitutes acceptance of any changes.</li>
                      <li>Effective Date: Changes become effective upon posting on the website.</li>
                    </ul>

                    <h3>11. Governing Law and Jurisdiction</h3>
                    <ul>
                      <li>Governing Law: These Terms are governed by the laws of Ontario, Canada.</li>
                      <li>Disputes: Any legal disputes will be resolved in Ontario, Canada.</li>
                    </ul>

                    <h3>12. Indemnification</h3>
                    <ul>
                      <li>Agreement: Vendors agree to indemnify and hold harmless Wedding Pricer (16301380 Canada Inc.), its officers, directors, employees, and affiliates from any claims, damages, or liabilities arising from their use of the platform or violation of these Terms.</li>
                    </ul>

                    <h3>13. Severability</h3>
                    <ul>
                      <li>Validity: If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will still apply.</li>
                    </ul>

                    <h3>14. Entire Agreement</h3>
                    <ul>
                      <li>Supersession: These Terms represent the entire agreement between Wedding Pricer and vendors, superseding any prior agreements or understandings.</li>
                    </ul>

                    <p>
                      For any questions or concerns about these Terms of service, please contact us at <a href="mailto:help@weddingpricer.com">help@weddingpricer.com</a>.
                    </p>


                  </div>
                </div>
                {/*  /.tab-content */}
              </div>
            </div>
          </ScrollspyNav>
        </div>
      </div>

      <footer className="theme-footer-one pt-130 md-pt-70">
        <div className="top-footer">
          <div className="container">
            <FooterFour />
          </div>
        </div>

        <div className="container">
          <div className="bottom-footer-content">
            {/* <CopyRight /> */}
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default TermsConditionsVendor;
