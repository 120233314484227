import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import CopyRight from "../../../../components/footer/CopyRight";
import ScrollspyNav from "react-scrollspy-nav";
import FooterFour from "../../../../components/footer/FooterFour";

const TermsConditionsUser = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          User Terms of Service
        </title>
      </Helmet>

      <Header />

      <div className="terms_and_policy">
        <div className="container">
          <ScrollspyNav
            scrollTargetIds={["opt1", "opt2", "opt3", "opt4", "opt5", "opt6"]}
            activeNavClass="active"
            offset={170}
            scrollDuration="300"
          >
            <div className="row align-items-start">
              <div className="col-lg-4 sidenav-sticky">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link" href="#opt2">
                      1. Terms of Service for Users
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-8">
                {/* Tab panes */}
                <div className="tab-content ">
                  <div id="opt1">
                    <h2 className="font-gilroy-bold">User terms of service</h2>
                    <div className="update-date">LAST UPDATED: 10/01/2024</div>
                    <p>
                      Welcome to Wedding Pricer, a service of 16301380 Canada Inc. Please carefully read these Terms of Service (the "Terms") as they govern your use of the platform. By using Wedding Pricer, you agree to these Terms.
                    </p>
                    <h3>1. Eligibility</h3>
                    <p>
                      To use the Wedding Pricer platform, you must be at least 18 years old. By creating an account, you confirm that you meet this age requirement.
                    </p>
                    <h3>2. Your Account and Information</h3>
                    <p>
                      To use Wedding Pricer, you must create an account. You agree to provide accurate and up-to-date information including your name, contact information, wedding location, budget range, preferred vendors, and a description of what you seek from vendors. You are responsible for maintaining the confidentiality of your login credentials and for all activities that occur under your account.
                    </p>
                    <h3>3. User Conduct</h3>
                    <p>
                      You agree to:
                    </p>
                    <ul>
                      <li>
                        Refrain from posting offensive or inappropriate content.
                      </li>
                      <li>
                        Be honest in your interactions with vendors.
                      </li>
                      <li>
                        Notify Wedding Pricer when you find a vendor through the platform to receive your gift card. You are required to report when you find a vendor through the platform so that we can collect our commission.
                      </li>
                    </ul>
                    <p>
                      Failure to report a vendor engagement through the platform will result in forfeiture of the gift card. Repeated failure to comply may result in suspension or termination of your account.
                    </p>
                 
                    <h3>4. No Involvement in Agreements</h3>
                    <p>
                      Wedding Pricer is solely a platform to connect users with vendors. We do not guarantee the quality, pricing, or availability of services provided by vendors. Wedding Pricer is not involved in any agreement between users and vendors. Any issues related to refunds, cancellations, or disputes must be resolved between the user and vendor.
                    </p>

                    <h3>5. Liability and Disclaimers</h3>
                    <p>
                      We are not liable for any loss or damage arising from the use or inability to use the platform, or for any loss or damages due to vendors unable to fulfill their part of the business. Customers and vendors should have a separate agreement to cover this if they wish.
                    </p>
                    <p>
                      You are responsible for ensuring that any content, files, photos, or other materials you upload to the platform comply with applicable laws. By uploading content, you grant Wedding Pricer the right to store, process, analyze, and use these materials for machine learning and display them on your profile. You also represent that you have the legal right to upload and share these materials.
                    </p>
                    <h3>6. Gift Card Policy</h3>
                    <p>
                      If you engage with a vendor you found through Wedding Pricer, you must inform us. This ensures that you receive the gift card and that we collect the applicable commission fee. If you do not report this engagement, Wedding Pricer reserves the right to recover the commission fees from you and/or the vendor, as well as suspend or terminate your account.
                    </p>
                 
                    <h3>7. Indemnification</h3>
                    <p>
                      You agree to indemnify and hold harmless Wedding Pricer (16301380 Canada Inc.), its officers, directors, employees, and affiliates from any claims, damages, or liabilities arising from your use of the platform or violation of these Terms.
                    </p>

                    <h3>8. Changes to the Terms</h3>
                    <p>
                      Wedding Pricer reserves the right to modify or update these Terms at any time. Any changes will be posted on the website and sent via email. It is your responsibility to review the Terms regularly, and continued use of the platform after changes are posted constitutes acceptance of the modified Terms.
                    </p>

                    <h3>9. Governing Law</h3>
                    <p>
                      These Terms are governed by the laws of Ontario, Canada. Any legal disputes arising from these Terms will be resolved in Ontario, Canada.
                    </p>

                    <h3>11. Entire Agreement</h3>
                    <p>
                      These Terms represent the entire agreement between Wedding Pricer and the users, superseding any prior agreements or understandings.
                    </p>

                    <h3>12. User Responsibilities for Uploaded Content</h3>
                    <p>
                      You are responsible for ensuring that any content you upload, including photos, videos, or other files, complies with all relevant laws. By uploading this content, you grant Wedding Pricer permission to use, process, and display it as part of the service, including for machine learning and analysis purposes.
                    </p>
                 
                    <h3>13. Account Suspension and Termination</h3>
                    <p>
                      We reserve the right to suspend or terminate your account, at our sole discretion, at any time and for any reason, including but not limited to violations of these Terms of Service, suspicious activity, or any behavior that we believe may harm our platform or other users. You will be notified of any suspension or termination, but we are not obligated to provide a reason for our decision.
                    </p>
                    
                  </div>
                </div>
                {/*  /.tab-content */}
              </div>
            </div>
          </ScrollspyNav>
        </div>
      </div>

      <footer className="theme-footer-one pt-130 md-pt-70">
        <div className="top-footer">
          <div className="container">
            <FooterFour />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            {/* <CopyRight /> */}
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default TermsConditionsUser;
