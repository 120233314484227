import React from "react";

const FormEvent = () => {
  const handleSubmit = (event) => {
    const email = document.getElementById('email-address').value;
    window.open('/signup/user?email=' + email);
  };

  return (
    <form>
      <input type="text" id='email-address' placeholder="Email address" />
      <button onClick={handleSubmit}>Sign up</button>
    </form>
  );
};

export default FormEvent;
