import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const SignUpFormUser = () => {
  // for password show hide
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  // for Re password show hide
  const [rePasswordShown, setRePasswordShown] = useState(false);
  const toggleRePasswordVisiblity = () => {
    setRePasswordShown(rePasswordShown ? false : true);
  };

  // for validation
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Entered value does not match email format"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    acceptTerms: Yup.bool().oneOf(
      [true],
      "Accept Terms of Service is required"
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema)
  };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState, setError } = useForm(formOptions);
  const { errors } = formState;


  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const defaultEmail = urlParams.get('email');
  if (defaultEmail) {
    setTimeout(() => {
      document.getElementById('input-email').value = defaultEmail;
    }, 500);

  }

  function onSubmit(data, e) {

    const host = "https://app.weddingpricer.com";

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        Email: data.email,
        FirstName: data.firstName,
        LastName: data.lastName,
        PhoneNumber: data.phoneNumber,
        Password: data.password,
        ConfirmPassword: data.confirmPassword,
        AgreeTos: data.acceptTerms,
        IsVendor: false
      })
    };

    fetch(`${host}/api/user/signup`, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.isSuccess === true) {
          window.open('/signup/user/complete');
        } else {
          setError(data.errors[0].fieldName, {
            type: "main",
            message: data.errors[0].message,
          });
        }
      });
  }

  return (
    <>
      <form className="user-data-form " onSubmit={handleSubmit(onSubmit)}>
        <div className="row">

          <div className="col-12">
            <div className="input-group-meta mb-40">
              <label>Email</label>
              <input
                placeholder="Enter Your Email"
                name="email"
                id="input-email"
                type="text"
                {...register("email",)}
                className={` ${errors.email ? "is-invalid" : ""}`}
              />

              {errors.email && (
                <div className="invalid-feedback">{errors.email?.message}</div>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="input-group-meta mb-40">
              <label>First Name</label>
              <input
                type="text"
                placeholder="Enter First Name"
                name="firstName"
                {...register("firstName")}
                className={` ${errors.firstName ? "is-invalid" : ""}`}
              />

              {errors.firstName && (
                <div className="invalid-feedback">{errors.firstName?.message}</div>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="input-group-meta mb-40">
              <label>Last Name</label>
              <input
                type="text"
                placeholder="Enter Last Name"
                name="name"
                {...register("lastName")}
                className={` ${errors.lastName ? "is-invalid" : ""}`}
              />

              {errors.lastName && (
                <div className="invalid-feedback">{errors.lastName?.message}</div>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="input-group-meta mb-40">
              <label>Phone Number</label>
              <input
                type="text"
                placeholder="Enter Phone Number"
                name="phoneNumber"
                {...register("phoneNumber")}
                className={` ${errors.phoneNumber ? "is-invalid" : ""}`}
              />

              {errors.phoneNumber && (
                <div className="invalid-feedback">{errors.phoneNumber?.message}</div>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="input-group-meta mb-40">
              <label>Password</label>
              <input
                placeholder="Enter Password"
                name="password"
                type={passwordShown ? "text" : "password"}
                {...register("password")}
                className={` ${errors.password ? "is-invalid" : ""}`}
              />
              {errors.password && (
                <div className="invalid-feedback">
                  {errors.password?.message}
                </div>
              )}
              {/* End error msg */}
              <span
                className="placeholder_icon"
                onClick={togglePasswordVisiblity}
              >
                <span
                  className={
                    passwordShown ? "passVicon eye-slash" : "passVicon"
                  }
                >
                  <img src="images/icon/view.svg" alt="" />
                </span>
              </span>
              {/* End placeholder icon */}
            </div>
          </div>
          <div className="col-12">
            <div className="input-group-meta mb-25">
              <label>Re-type Password</label>
              <input
                placeholder="Enter Password"
                name="confirmPassword"
                type={rePasswordShown ? "text" : "password"}
                {...register("confirmPassword")}
                className={` ${errors.confirmPassword ? "is-invalid" : ""}`}
              />
              {errors.confirmPassword && (
                <div className="invalid-feedback">
                  {errors.confirmPassword?.message}
                </div>
              )}
              {/* End error msg */}
              <span
                className="placeholder_icon"
                onClick={toggleRePasswordVisiblity}
              >
                <span
                  className={
                    rePasswordShown ? "passVicon eye-slash" : "passVicon"
                  }
                >
                  <img src="images/icon/view.svg" alt="" />
                </span>
              </span>
              {/* End placeholder icon */}
            </div>
          </div>
          <div className="col-12">
            <div className="agreement-checkbox d-flex justify-content-between align-items-center sm-mt-10">
              <div className="position-relative">
                <input
                  name="acceptTerms"
                  type="checkbox"
                  {...register("acceptTerms")}
                  id="acceptTerms"
                  className={` ${errors.acceptTerms ? "is-invalid" : ""}`}
                />

                <label htmlFor="acceptTerms">
                  By clicking "SIGN UP" I agree to the <Link to='/tos/user' target="_blank">Terms of Service</Link>.
                </label>
                {errors.acceptTerms && (
                  <div className="invalid-feedback">
                    {errors.acceptTerms?.message}
                  </div>
                )}
              </div>
            </div>

          </div>
          <div className="col-12">
            <button type="submit" className="theme-btn-one mt-25 mb-40">
              Sign Up
            </button>
          </div>

        </div>
      </form>

    </>
  );
};

export default SignUpFormUser;
